<template>
  <div>
    <v-card class="mt-2" :class="{ 'p-6': !mobile, 'p-2': mobile }">
      <v-card-title
        class="justify-space-between h4"
        :class="{ 'd-flex col align-center': !mobile, 'py-0': mobile }"
      >
        <span v-if="!mobile">{{ $t("mystudies") }}</span>
        <create-study-button
          class="pb-6 px-0"
          :class="{ 'col-12': mobile }"
        ></create-study-button>
        <span v-if="mobile">{{ $t("mystudies") }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <study-list></study-list>
    </v-card>
  </div>
</template>

<script>
import StudyList from "../components/center/StudyList.vue";
import CreateStudyButton from "../components/center/CreateStudyButton.vue";
export default {
  components: { StudyList, CreateStudyButton },
  i18n: {
    messages: {
      en: {
        mystudies: "Your Studies"
      },
      fr: {
        mystudies: "Vos Etudes"
      }
    }
  }
};
</script>
