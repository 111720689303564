<template>
  <div>
    <v-data-table :headers="headers" :items="items" light>
      <template v-slot:[`item.studyName`]="{ item }">
        <v-chip outlined :href="goToStudy(item.studyName)">{{
          item.studyName
        }}</v-chip>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ $t(`roles.${item.role}`) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-right">
          <v-menu offset-y v-if="canDuplicate(item.role)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item @click="initiateDuplication(item.studyName)">{{
                $t("duplicate")
              }}</v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>{{ `${$t("duplication")} ${studyToDuplicate}` }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newStudyName"
            :label="$t('newStudyName')"
            :rules="[rules.studyName]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="duplicate()">{{
            $t("action.save")
          }}</v-btn>
          <v-btn color="danger" text @click="dialog = false">{{
            $t("action.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { clone } from "spiral-client";
import users from "@/mixin/users";

export default {
  data() {
    return {
      dialog: false,
      studyToDuplicate: "",
      newStudyName: "",
      headers: [
        {
          text: this.$t("domain.study"),
          value: "studyName",
          class: "title"
        },
        { text: "Role", value: "role", class: "title" },
        { text: "", value: "actions" }
      ],
      rules: {
        studyName: value => {
          const regex = /-/;
          return (
            regex.test(value) || 'Studies name should contain "-" character'
          );
        }
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["studies"]),
    items() {
      return Object.keys(this.studies).map(study => {
        return {
          studyName: study,
          role: this.studies[study]?.role
        };
      });
    }
  },
  methods: {
    ...mapMutations("auth", ["setProfile"]),
    initiateDuplication(studyName) {
      this.studyToDuplicate = studyName;
      this.newStudyName = `${studyName}-COPY`;
      this.dialog = true;
    },
    goToStudy(studyName) {
      return `./${studyName}/`;
    },
    async duplicate() {
      const studyExist = await this.drivers.studyDriver
        .getByName(this.newStudyName)
        .catch(() => undefined);
      if (this.newStudyName && !studyExist) {
        const study = await this.drivers.studyDriver.getByName(
          this.studyToDuplicate
        );
        const clonedStudy = clone(study);
        clonedStudy.study(this.newStudyName);
        const newStudy = clonedStudy.get();
        await this.drivers.studyDriver.save(newStudy);
        await this.reloadProfile();
        this.dialog = false;
      } else if (studyExist) this.setError("This study name already exist");
    },
    canDuplicate(role) {
      return [
        "developer",
        "administrator",
        "studycoordinator",
        "dataadministrator"
      ].includes(role);
    }
  },
  mixins: [users],
  i18n: {
    messages: {
      en: {
        duplicate: "DUPLICATE",
        studyToDuplicate: "Study to duplicate",
        newStudyName: "New study name",
        duplication: "Duplication of study"
      },
      fr: {
        duplicate: "DUPLIQUER",
        studyToDuplicate: "Etude à dupliquer",
        newStudyName: "Nouveau nom de l'étude",
        duplication: "Duplication de l'étude"
      }
    }
  }
};
</script>

<style>
.title {
  font-size: 2rem;
}
</style>
