<template>
  <div>
    <v-btn
      color="success"
      outlined
      :rounded="!mobile"
      @click="dialog = true"
      id="new-study-btn"
      :block="mobile"
    >
      {{ $t("newStudy") }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>{{ $t("studyParameters") }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="studyName"
            :label="$t('studyName')"
            :rules="[rules.studyName]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="createStudy()">{{
            $t("action.save")
          }}</v-btn>
          <v-btn color="danger" text @click="dialog = false">{{
            $t("action.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { StudyTemplate, PatientTemplate } from "spiral-studio";
import { Site } from "spiral";
import { mapGetters, mapMutations } from "vuex";
import users from "@/mixin/users";

export default {
  data() {
    return {
      dialog: false,
      studyName: undefined,
      rules: {
        studyName: value => {
          const regex = /-/;
          return (
            regex.test(value) || 'Studies name should contain "-" character'
          );
        }
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "studies",
      "title",
      "givenName",
      "surname",
      "role",
      "email",
      "phone",
      "userid",
      "id",
      "email_verified"
    ])
  },
  methods: {
    ...mapMutations("auth", ["setProfile"]),
    async createStudy() {
      const studyExist = await this.drivers.studyDriver
        .getByName(this.studyName)
        .catch(() => undefined);
      if (this.studyName && !studyExist) {
        const study = await this.addStudy();
        await this.addSite(study);
        await this.addPatient(study);
        await this.reloadProfile();
        this.dialog = false;
      } else if (typeof studyExist != "undefined") {
        this.setError("This study name already exist");
      }
    },
    async addStudy() {
      await this.drivers.studyDriver.save(new StudyTemplate(this.studyName));
      return await this.drivers.studyDriver.getByName(this.studyName);
    },
    async addSite(study) {
      const site = new Site("TEST01", { name: "Test Center" });
      return this.drivers.siteDriver.save(study, site);
    },
    async addPatient(study) {
      const sites = await this.drivers.siteDriver.getAll(study);
      await this.savePatient(study, new PatientTemplate(study, sites[0]));
    },
    async savePatient(study, patient) {
      const keys = await this.drivers.patientDriver.save(study, patient);
      const savedPatient = patient.update(keys);
      for (const i of patient.interviews) {
        await this.drivers.interviewDriver.save(study, savedPatient, i);
      }
    }
  },
  mixins: [users],
  i18n: {
    messages: {
      en: {
        newStudy: "New Study",
        studyParameters: "New study",
        studyName: "Study name"
      },
      fr: {
        newStudy: "Nouvelle Etude",
        studyParameters: "Nouvelle Etude",
        studyName: "Nom de l'étude"
      }
    }
  }
};
</script>
