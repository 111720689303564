<template>
  <div>
    <v-card class="px-6 py-3 mt-2">
      <div
        class="d-flex align-center"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <div>{{ $t("prompt") }}</div>
        <v-chip outlined class="ml-2" color="info" href="/track"
          ><strong>QUALITY DESK</strong>
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      en: {
        prompt:
          "Do you wish to follow up the EDC compliance? Submit a proposal? Report a bug?"
      },
      fr: {
        prompt:
          "Vous souhaitez suivre la conformité de l'EDC ? Soumettre une proposition ? Signaler un bug ?"
      }
    }
  }
};
</script>
