<template>
  <center-layout />
</template>

<script>
import CenterLayout from "../layouts/CenterLayout.vue";
export default {
  components: { CenterLayout }
};
</script>
